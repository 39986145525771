import Vue from 'vue'
import Vuex from 'vuex'
import aboutus from './modules/about-us'
import products from './modules/products'
import news from './modules/news'
import albums from './modules/albums'
import sliders from './modules/sliders'
import contactus from './modules/contact-us'
import categories from './modules/categories'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    aboutus,
    products,
    news,
    albums,
    sliders,
    contactus,
    categories
  }
})
