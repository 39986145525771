import axiosInstance from 'axios'
import i18n from './i18n/i18n'

const axios = axiosInstance.create({
  baseURL: 'https://api.general-pompe.com/api',
  // baseURL: 'https://matador-api.beetronix.net/api',

  headers: { common: { 'accept-language': i18n.locale } }
})

export default axios
