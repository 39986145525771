<template>
    <section class="about pb-4 pt-lg-8 pt-8">
        <PageLoader v-if="loading" />
        <div v-if="!loading" class="container">
            <h1 class="text-center text-primary mb-3 fw-bold">
                {{ $t("about-us") }}
            </h1>
            <p v-html="aboutus.find((t) => t.key == 'about')?.value"></p>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

export default {
  name: 'AboutView',
  components: { PageLoader },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      aboutus: (state) => state.aboutus.aboutus
    })
  },

  created () {
    if (!this.aboutus.length) {
      this.loading = true
      this.$store.dispatch('aboutus/fetchAll').finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
    min-height: 80vh;
}
</style>
