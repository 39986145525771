import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import es from './locales/es.json'

Vue.use(VueI18n)

const messages = {
  en: en,
  es: es
}

const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'en',
  messages
})

export const changeLang = (lang) => {
  i18n.locale = lang
  localStorage.setItem('locale', lang)
  document.documentElement.lang = lang
  if (lang === 'ar') {
    document.documentElement.dir = 'rtl'
  } else {
    document.documentElement.dir = 'ltr'
  }
}

if ('locale' in localStorage) {
  changeLang(localStorage.getItem('locale'))
} else {
  localStorage.setItem('locale', i18n.locale)
}

export default i18n
