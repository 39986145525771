<template>
    <div class="loading">
        <img src="../assets/images/bouncing-circles.svg" alt="Loading..." />
    </div>
</template>

<script>
export default {
  name: 'PageLoader'
}
</script>

<style lang="scss">
.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
</style>
