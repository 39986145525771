<template>
    <section class="container contact-us pt-lg-8">
        <PageLoader v-if="loading" />
        <h2 v-if="!loading" class="mb-5 text-primary text-center fw-bold">
            {{ $t("contact-us") }}
        </h2>
        <div v-if="!loading" class="row">
            <div class="col-md-12">
                <form class="px-4" @submit.prevent="validateForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group text-start">
                                <label class="label">{{
                                    $t("full-name")
                                }}</label>
                                <input
                                    type="text"
                                    v-model="fullName"
                                    class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                    :class="{ 'is-invalid': errors.fullName }"
                                    :placeholder="$t('enter-your-name')"
                                />
                                <div v-if="errors.fullName" class="text-danger">
                                    {{ errors.fullName }}
                                </div>
                            </div>
                            <div class="form-group text-start">
                                <label class="label">{{ $t("email") }}</label>
                                <input
                                    type="email"
                                    v-model="email"
                                    class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                    :class="{ 'is-invalid': errors.email }"
                                    :placeholder="$t('enter-your-email')"
                                />
                                <div v-if="errors.email" class="text-danger">
                                    {{ errors.email }}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group text-start">
                                <label class="label">{{
                                    $t("phone-number")
                                }}</label>
                                <input
                                    type="text"
                                    v-model="phoneNumber"
                                    class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                    :class="{
                                        'is-invalid': errors.phoneNumber,
                                    }"
                                    :placeholder="$t('enter-your-phone-number')"
                                />
                                <div
                                    v-if="errors.phoneNumber"
                                    class="text-danger"
                                >
                                    {{ errors.phoneNumber }}
                                </div>
                            </div>
                            <div class="form-group text-start">
                                <label class="label">{{ $t("type") }}</label>
                                <input
                                    type="text"
                                    v-model="messageType"
                                    class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                    :class="{
                                        'is-invalid': errors.messageType,
                                    }"
                                    :placeholder="
                                        $t('choose-the-type-of-your-message')
                                    "
                                />
                                <div
                                    v-if="errors.messageType"
                                    class="text-danger"
                                >
                                    {{ errors.messageType }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group text-start">
                            <label class="label">{{ $t("message") }}</label>
                            <textarea
                                v-model="message"
                                class="form-control mb-4 mt-1 rounded-3 py-4 bg-secondary"
                                :class="{ 'is-invalid': errors.message }"
                                :placeholder="$t('enter-your-message')"
                            ></textarea>
                            <div v-if="errors.message" class="text-danger">
                                {{ errors.message }}
                            </div>
                            <button
                                type="submit"
                                class="btn btn-primary rounded-4 text-white mb-4 px-5 py-2"
                            >
                                {{ $t("send") }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div
                v-for="branch in branches"
                :key="branch.id"
                class="text-center col-md-12"
            >
                <h2 class="text-primary fw-bold mb-5">
                    {{ $t("get-touch-with-us") }}
                </h2>
                <div class="mb-4">
                    <span>
                        {{ branch?.contacts.email }}
                    </span>
                </div>
                <div class="mb-4">
                    <span>
                        {{ branch?.contacts.phone }}
                    </span>
                </div>
                <div class="mb-4">
                    <span>
                        {{ branch?.contacts.address }}
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PageLoader from '@/components/PageLoader.vue'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      fullName: '',
      email: '',
      phoneNumber: '',
      messageType: '',
      message: '',
      errors: {},
      loading: false
    }
  },

  components: { PageLoader },
  methods: {
    validateForm () {
      this.errors = {}

      if (!this.fullName) {
        this.errors.fullName = this.$t('full-name-is-required')
      }
      if (!this.email) {
        this.errors.email = this.$t('email-is-required')
      } else if (!this.isValidEmail(this.email)) {
        this.errors.email = this.$t('invalid-email-address')
      }
      if (!this.phoneNumber) {
        this.errors.phoneNumber = this.$t('phone-number-is-required')
      } else if (!this.isValidPhoneNumber(this.phoneNumber)) {
        this.errors.phoneNumber = this.$t('invalid-phone-number')
      }
      if (!this.messageType) {
        this.errors.messageType = this.$t('message-type-is-required')
      }
      if (!this.message) {
        this.errors.message = this.$t('message-is-required')
      }

      if (Object.keys(this.errors).length === 0) {
        this.$store.dispatch('contactus/sendFeedback', {
          name: this.name,
          email: this.email,
          type: this.type,
          body: this.message
        })
      }
    },
    isValidEmail (email) {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return re.test(email)
    },
    isValidPhoneNumber (phoneNumber) {
      const re = /^[0-9]{10,15}$/ // Simple phone number validation
      return re.test(phoneNumber)
    }
  },
  computed: {
    ...mapState({
      branches: (state) => state.contactus.branches
    })
  },
  created () {
    if (!this.branches.length) {
      this.loading = true
      this.$store.dispatch('contactus/fetchAll').finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-us {
    min-height: 80vh;
}
input::placeholder {
    color: #b9b9b9;
}
textarea::placeholder {
    color: #b9b9b9;
}
</style>
