var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pb-5 pt-lg-8 pt-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 product d-flex flex-column justify-content-center border-0 pb-2"},[_c('div',{staticClass:"card-img-container rounded-4"},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.imageUrl
                            ? _vm.imageUrl
                            : _vm.getProductBySlug(_vm.$route.params.slug)
                                  ?.primaryImage.url,"alt":"..."}})]),_c('div',{staticClass:"d-flex mt-2"},_vm._l((_vm.getProductBySlug(_vm.$route.params.slug)
                        ?.images),function(image){return _c('div',{key:image.id,staticClass:"card-second-imgs-container me-2",on:{"click":function($event){_vm.imageUrl = image.url}}},[_c('img',{attrs:{"src":image?.url,"width":"50%","height":"80%"}})])}),0)]),_c('div',{staticClass:"col-lg-6 product border-0"},[_c('div',{staticClass:"card-body text-lg-start"},[_c('h5',{staticClass:"card-title text-start mt-sm-2 fw-bold pb-2"},[_vm._v(" "+_vm._s(_vm.getProductBySlug(_vm.$route.params.slug)?.name)+" ")]),_c('span',{staticClass:"card-text text-start d-flex align-items-center mb-1"},[(
                            _vm.getProductBySlug(_vm.$route.params.slug)
                                ?.attributes[0]?.image.url
                        )?_c('img',{staticClass:"ms-1",attrs:{"width":"15","height":"20","src":_vm.getProductBySlug(_vm.$route.params.slug)
                                ?.attributes[0]?.image.url,"alt":""}}):_vm._e(),_c('div',{staticClass:"value ms-1",domProps:{"innerHTML":_vm._s(
                            _vm.getProductBySlug(_vm.$route.params.slug)
                                ?.attributes[0]?.value
                        )}}),_c('br')]),_c('div',{staticClass:"d-flex align-items-center mt-2"},[_c('span',{staticClass:"card-text text-lg-start d-flex align-items-center"},[(
                                _vm.getProductBySlug(_vm.$route.params.slug)
                                    ?.attributes[1]?.image.url
                            )?_c('img',{staticClass:"ms-1 me-2",attrs:{"width":"20","height":"20","src":_vm.getProductBySlug(_vm.$route.params.slug)
                                    ?.attributes[1]?.image.url,"alt":""}}):_vm._e(),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                                _vm.getProductBySlug(_vm.$route.params.slug)
                                    ?.attributes[1]?.value
                            )}})]),_c('span',{staticClass:"card-text text-lg-start d-flex align-items-center ms-4"},[(
                                _vm.getProductBySlug(_vm.$route.params.slug)
                                    ?.attributes[2]?.image.url
                            )?_c('img',{staticClass:"ms-1 me-2",attrs:{"width":"20","height":"20","src":_vm.getProductBySlug(_vm.$route.params.slug)
                                    ?.attributes[2]?.image.url,"alt":""}}):_vm._e(),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                                _vm.getProductBySlug(_vm.$route.params.slug)
                                    ?.attributes[2]?.value
                            )}})])]),_c('div',{staticClass:"pt-2",domProps:{"innerHTML":_vm._s(
                        _vm.getProductBySlug(this.$route.params.slug)
                            ?.description
                    )}})])])]),_c('div',{staticClass:"container py-5"},[_c('h2',{staticClass:"text-center fw-bold text-primary"},[_vm._v(" "+_vm._s(_vm.$t("related-products"))+" ")]),_c('div',{staticClass:"products d-flex gap-4 flex-wrap justify-content-center pt-5",on:{"click":function($event){_vm.imageUrl = null}}},_vm._l((_vm.products.filter(
                    (t) => !(t == _vm.getProductBySlug(this.$route.params.slug))
                )),function(product){return _c('ProductCard',{key:product.id,attrs:{"product":product}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }