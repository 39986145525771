<template>
    <section class="products container pb-5 pt-lg-8 pt-8">
        <PageLoader v-if="!loading" />
        <div v-if="loading">
            <h2 class="mb-5 fw-bold text-primary text-center">
                {{ $t("our-products") }}
            </h2>
            <div class="row">
                <div class="ps-md-6 col-md-8 offset-md-4 d-flex">
                    <router-link
                    :to="{
                      name: 'products'
                      }"
                    :class="{'active': !$route.query.category  }"
                    class="btn btn-outline-primary rounded-4 ms-3 px-4 py-2">
                    {{$t('all')}}
                    </router-link>
                    <router-link
                        v-for="category in categories.slice(0, 2)"
                        :key="category.id"
                        :to="{
                            name: 'products',
                            query: { category: category.slug },
                        }"
                        :class="{'active': category.slug === $route.query.category }"
                        class="btn btn-outline-primary rounded-4 ms-3 px-4 py-2"
                    >
                        {{ category.name }}
                    </router-link>
                    <input
                        v-model="searchString"
                        class="form-control search rounded-4 ms-auto bg-secondary"
                        :placeholder="$t('search')"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="loading"
            class="products d-flex gap-4 flex-wrap justify-content-center pt-5"
        >
            <ProductCard
                v-for="product in filteredProducts"
                :key="product.id"
                :product="product"
            />
            <div
                v-if="filteredProducts.length ? false : true"
                class="text-center"
            >
                <h2 class="text-primary fw-bold">No Result</h2>
                <img
                    src="../assets/images/no-results.png"
                    class="text-center w-50"
                    alt=""
                />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ProductCard from '../components/ProductCard.vue'
import PageLoader from '@/components/PageLoader.vue'

export default {
  name: 'ProductsView',
  components: { PageLoader, ProductCard },
  data () {
    return {
      searchString: '',
      loading: true,
      test: ''
    }
  },
  computed: {
    ...mapState({
      products: (state) => state.products.products,
      categories: (state) => state.categories.categories
    }),

    ...mapGetters({
      getProductBySlug: 'products/getBySlug',
      getCategoriesBySlug: 'categories/getBySlug'
    }),

    filteredProducts () {
      return this.products
        .filter((product) =>
          product.name
            .toLocaleLowerCase()
            .includes(this.searchString.toLocaleLowerCase())
        )
        .filter((product) =>
          this.$route.query.category
            ? product.categoriesIds.includes(
              this.getCategoriesBySlug(
                this.$route.query.category
              ).id
            )
            : true
        )
    }
  },

  created () {
    if (!this.products.length) {
      this.loading = false
      this.$store.dispatch('products/fetchAll').finally(() => {
        this.loading = true
      })
    }
    if (!this.categories.length) {
      this.loading = false
      this.$store.dispatch('categories/fetchAll').finally(() => {
        this.loading = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
    min-height: 80vh;
}
.search {
    width: 35%;
}
.card {
    width: 200px;
    .card-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(250, 249, 249);
        height: 300px;
        width: 100%;
    }
    .card-img-top {
        height: 250px;
        width: 75px;
    }
}
.active {
    color: white !important;
    background-color: var(--bs-primary);
}
.btn:focus {
  color: white !important;
}
.btn:hover {
    color: white;
}
input::placeholder {
    color: #b9b9b9;
}
</style>
